<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/topic/list')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
    <el-form ref="form" class="form" :model="form">
        <div>
            <h4 class="bgl">内容信息</h4>
            <div class="fle">
                <div>话题名称：<input type="text" disabled v-model="form.name"></div>
            </div>
            <div>
                
             <el-form-item :required="true"  class="bold" label="话题分类：">
                
                 <el-select  @change='ftype($event)' v-model="form.f_type.id" placeholder="请选择">
                  <el-option
                    v-for="ite in options"
                    :key="ite.id"
                    :label="ite.name"
                    :value="ite.id">
                  </el-option>
                 </el-select>
                 <el-select v-if="options2.length > 0" v-model="form.c_type.id" placeholder="请选择" style="margin-left: 5px;">
                  <el-option
                    v-for="ite in options2"
                    :key="ite.id"
                    :label="ite.name"
                    :value="ite.id">
                  </el-option>
                 </el-select>
              </el-form-item>
                </div>
        
            
              <el-form-item :required="true"  class="bold"  label="是否为推荐话题：">
              <el-select style="width:120px;"  v-model="form.is_recommend" placeholder="请选择">
                  <el-option label="否" :value="0"></el-option>
                  <el-option label="是" :value="1"></el-option>
              </el-select>
              </el-form-item>
              
              <el-form-item :required="true"  class="bold"  label="版本活动：">
              <el-select style="width:120px;"  v-model="form.is_activity" placeholder="请选择">
                  <el-option label="启用" :value="1"></el-option>
                  <el-option label="禁用" :value="0"></el-option>
              </el-select>
              </el-form-item>
        </div>
        <div>
            <h4 class="bgl">基本信息</h4>
            <div class="flex">
              <div class="fle" style="min-width: 200px;">
                <div>生成时间：<input type="text" disabled v-model="form.created_at"> </div>
                <div>生成类型:<input type="text" disabled v-model="form.belongtext"></div>
                <div>生成账号:<input type="text" disabled v-model="form.phone"></div>
                <div>话题编号:<input type="text" disabled v-model="form.id"></div>
              </div>
              <div class="fle left" >
                <div>使用量：<input type="text" disabled v-model="form.num"></div>
                <div>最后使用时间：<input type="text" disabled v-model="form.updated_at"></div>
                <div>被收藏数：<input type="text" disabled v-model="form.follow_num"></div>
              </div>
            </div>
            
            <el-form-item :required="true"  class="bold"  label="使用状态：">
              <el-select v-model="form.status" placeholder="请选择">
                <el-option label="启用" :value="1"></el-option>
                <el-option label="禁用" :value="3"></el-option>
              </el-select>
            </el-form-item>
        </div>
      <el-form ref="form" :model="form" label-width="180px">
      <el-form-item class="mtop">
        <el-button v-if="!look" type="primary" style="margin-left: 45%;" @click="onSubmit">确认</el-button>
      </el-form-item>
    </el-form>
</el-form>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
        look:false,
        id:0,
          f_type:{
              id:''
          },
          c_type:{
              id:-1
          }
        },
        options:[],
        options2:[],
        status:'',
        is_recommend:'',
        is_activity:'',
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      postdata.c_name = postdata.second;
      let obj = {
          id:postdata.id,
          f_type:postdata.f_type.id,
          c_type:postdata.c_type.id,
          updated_at:postdata.updated_at,
          status:postdata.status,
          is_recommend:postdata.is_recommend,
          is_activity:postdata.is_activity
      }
      if(!obj.f_type || !obj.c_type || obj.c_type == -1){
          _this.$message.error('话题分类必选');
            return;
      }
      if(obj.is_recommend === ''){
          _this.$message.error('是否为推荐话题必选');
            return;
      }
      if(obj.is_activity === ''){
          _this.$message.error('版本活动必选');
            return;
      }
      if(obj.status === '' ){
          _this.$message.error('使用状态必选');
            return;
      }
      axios.post(config.topic,obj)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.goNav('/topic/list');
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
      ftype(e){
          let _this = this;
      if(e != -1){
      axios.get(config.selecttopicCategory,{params:{id:e,title:1}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options2 = response.data.data;
              _this.form.c_type.id = -1;
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      }else{
        _this.form.c_type.id = -1;
        _this.options2 = [];
      }
      },
      ftype2(e){
          let _this = this;
      axios.get(config.selecttopicCategory,{params:{id:e,title:1}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options2 = response.data.data;
              if(!_this.form.c_type){
                    _this.form.c_type = {id:-1}
              }
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
    init(a){
        let _this = this;
        let obj = JSON.parse(a);
        _this.look = obj.look;
        axios.get(config.topicInfo,{params:{id:obj.id}})
        .then(function (response) {
            if(response.data.code == 200){
                _this.form = response.data.data;
                _this.form.belongtext = _this.form.belong=='1'?'系统':_this.form.belong=='2'?'自定义':'';
                _this.form.id = obj.id;
                if(!_this.form.f_type){
                    _this.form.f_type = {id:''}
                    _this.form.c_type = {id:-1}
                }else{
                    _this.ftype2(_this.form.f_type.id);
                }
            }
          })
        .catch(function (error) {
          console.log(error);
        });
        axios.get(config.selecttopicCategory,{params:{id:0,title:0}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options = response.data.data
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },// 增加
    add() {
        this.form.second.push({
          id: 0,
          name: ""
        });
    },
    // 删除
    deleteData(index) {
      if (this.form.second.length - 1 < 1) {
        this.$alert("至少输入1项");
        return;
      } else {
        this.form.second.splice(index, 1);
      }
    },
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
  }
}
</script>
<style scoped>
.bold{
  font-weight: bold;
}
.form{
    margin-right: auto;
    text-align: left;
}
.form div{
  margin: 7px 0px;
}
.flex{
    display: flex;
}
.form .left{
  margin-left: 30px;
}
.mtop{
  margin-top: 20px;
}
.el-icon-close:before{
  font-size: 24px;
}
 div{
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}
.bgl{
    background-color: aliceblue;
    padding: 10px;
    margin-bottom: 5px;
}
.fle div{
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 0 18px 10px 0;
    box-sizing: border-box;
    font-weight: bold;
}
.fle div input{
  height: 20px;
}
</style>
